
<template>
<div class="row justify-content-center" style="height: 100vh;">
  <div class="col-md-8 col-lg-6 col-xl-5 d-flex align-items-center justify-content-center flex-column mx-auto">
    <div class="card shadow-none">
      <div class="card-body p-4">
        <div class="text-center">
          <h3 class="mt-4 text-uppercase font-weight-bold">Page not found </h3>
          <p class="text-muted mb-3"> The page you are looking for might have been removed had its name changed or is temporarily not available</p>
          <router-link to="/" class="btn btn-primary mt-3"><i class="mdi mdi-reply mr-1"></i> Return home</router-link>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

export default {

}
</script>
